



















import Vue from "vue";
import UserTable from "@/components/avatar/UserTable.vue";
import { mapState } from "vuex";
import { ActionTypes } from "@/store/action-types";
import { UserFilter, UserInvite } from "@/models/user";
import { Maybe, OrganizationUser, Role, User } from "@/generated/graphql";

export default Vue.extend({
  components: {
    UserTable
  },
  data() {
    return {
      isFetchingUsers: false,
      activateOpenWorkspaceLink: false,
      eAdvocacy: localStorage.ea
    };
  },
  computed: {
    ...mapState({
      users: state => state.user.users.list
    }),
    roles(): Maybe<Role[]> {
      return this.$storeTyped.getters.getUserRoles;
    },
    hasNextPage(): boolean {
      return this.$storeTyped.state.user.users.hasNextPage;
    }
  },
  methods: {
    onFilterChange(filter: UserFilter, loadMore = false) {
      this.isFetchingUsers = true;
      this.$store
        .dispatch(ActionTypes.FETCH_USERS, { filter, loadMore })
        .finally(() => (this.isFetchingUsers = false));
    },
    async onEdit({ user, roles }: UserInvite) {
      await this.$storeTyped.dispatch(
        ActionTypes.UPDATE_ORGANIZATION_USER_ROLES,
        {
          id: user.id,
          input: roles
        }
      );
    },
    onInvite({ user, roles }: UserInvite) {
      this.$storeTyped.dispatch(ActionTypes.INVITE_USER, {
        email: user.email,
        roles
      });
    },
    onReinvite({ email, roles }: OrganizationUser) {
      this.$storeTyped.dispatch(ActionTypes.REINVITE_USER, {
        email,
        roles: (roles as Role[]).map(r => r.value)
      });
    },
    onDelete(user: User) {
      this.$storeTyped.dispatch(ActionTypes.DELETE_USER, user.id);
    }
  }
});
